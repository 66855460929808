import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { addWeeks, format } from 'date-fns';
import { getCurrentWeek } from '../../shared/utils';
import { fr } from 'date-fns/locale';
import { toURL } from './api-utils';
export class ServiceService {
    constructor(http) {
        this.http = http;
        this.$weekInterval = new BehaviorSubject(0);
    }
    get(start, end) {
        return this.http.get(toURL('v1/services'), {
            params: {
                startAt: start.toISOString(),
                endAt: end.toISOString(),
            },
        }).toPromise();
    }
    getById(id) {
        return this.http.get(toURL(`v1/services/${id}`)).toPromise();
    }
    getBarmen(id) {
        return this.http.get(toURL(`v1/services/${id}/barmen`)).toPromise();
    }
    create(services) {
        return this.http.post(toURL('v1/services'), services).toPromise();
    }
    update(service) {
        return this.http.put(toURL(`v1/services/${service.id}`), service).toPromise();
    }
    delete(id) {
        return this.http.post(toURL(`v1/services/${id}/delete`), null).toPromise();
    }
    getWeek() {
        return new Observable((week) => {
            this.$weekInterval.subscribe((weekInterval) => {
                const current = getCurrentWeek();
                week.next({
                    start: addWeeks(current.start, weekInterval),
                    end: addWeeks(current.end, weekInterval),
                });
            });
        });
    }
    getPlanning(start, end) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const days = [];
            const services = yield this.get(start, end);
            services.forEach((service) => {
                const name = format(new Date(service.startAt), 'EEE', { locale: fr });
                const day = days.find(currentDay => currentDay.name === name);
                if (day)
                    return day.services.push(service);
                days.push({
                    name,
                    date: service.startAt,
                    active: false,
                    services: [service],
                });
            });
            return days;
        });
    }
}

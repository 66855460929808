import * as tslib_1 from "tslib";
import { TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';
export class ModalComponent {
    constructor(dialog, location) {
        this.dialog = dialog;
        this.location = location;
        this._afterClosed = new Subject();
    }
    openDialog() {
        this._dialogRef = this.dialog.open(this.content, this.config);
        this._dialogRef.afterClosed().subscribe((result) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this._dialogRef = null;
            this._afterClosed.next(result);
            this.location.back();
        }));
    }
    close(result) {
        if (this._dialogRef)
            this._dialogRef.close(result);
    }
    afterClosed() {
        return this._afterClosed.asObservable();
    }
    ngOnInit() {
        // Workaround https://github.com/angular/angular/issues/15634
        setTimeout(() => this.openDialog());
    }
}

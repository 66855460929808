// This file is automatically generated by inject.env.js script
/* tslint:disable */
export const environment = {
  production: true,
  RECAPTCHA_SITE_KEY: "6Ldbh14UAAAAAC_hGGi0xaHS5Om-Qln1UQV_lW-7",
  BUGSNAG_KEY: undefined,
  API_HOSTNAME: "api.kfet-insa.fr",
  RELEASE_STAGE: "production",
  JWT_DAY_EXP_LONG: 30,
  JWT_DAY_EXP: 1,
};

<mat-nav-list>
  <mat-list>
    <ng-container *ngFor="let subMenu of menu">
      <ng-container *ngIf="isVisible(subMenu)">
        <h3 mat-subheader *ngIf="subMenu.name">{{subMenu.name}}</h3>
        <div *ngFor="let link of subMenu.links">
          <a mat-list-item (click)="onClick(link)" *ngxPermissionsOnly="link.permissions">{{link.name}}</a>
        </div>
        <mat-divider></mat-divider>
      </ng-container>
    </ng-container>
  </mat-list>
</mat-nav-list>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "./confirmation-dialog.component";
var styles_ConfirmationDialogComponent = [];
var RenderType_ConfirmationDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ConfirmationDialogComponent, data: {} });
export { RenderType_ConfirmationDialogComponent as RenderType_ConfirmationDialogComponent };
export function View_ConfirmationDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i0.ɵdid(1, 81920, null, 0, i1.MatDialogTitle, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], null, null), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 3, "mat-dialog-content", [["class", "mat-dialog-content"], ["fxLayout", "column"]], null, null, null, null, null)), i0.ɵdid(4, 16384, null, 0, i1.MatDialogContent, [], null, null), (_l()(), i0.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵeld(7, 0, null, null, 9, "mat-dialog-actions", [["class", "mat-dialog-actions"], ["fxLayoutAlign", "center"]], null, null, null, null, null)), i0.ɵdid(8, 16384, null, 0, i1.MatDialogActions, [], null, null), (_l()(), i0.ɵeld(9, 0, null, null, 3, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 11).dialogRef.close(i0.ɵnov(_v, 11).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i0.ɵdid(10, 180224, null, 0, i3.MatButton, [i0.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), i0.ɵdid(11, 606208, null, 0, i1.MatDialogClose, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i0.ɵted(-1, 0, ["Oui"])), (_l()(), i0.ɵeld(13, 0, null, null, 3, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 15).dialogRef.close(i0.ɵnov(_v, 15).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i0.ɵdid(14, 180224, null, 0, i3.MatButton, [i0.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), i0.ɵdid(15, 606208, null, 0, i1.MatDialogClose, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i0.ɵted(-1, 0, ["Non"]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_7 = true; _ck(_v, 11, 0, currVal_7); var currVal_12 = false; _ck(_v, 15, 0, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.data.title; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.data.message; _ck(_v, 6, 0, currVal_2); var currVal_3 = (i0.ɵnov(_v, 10).disabled || null); var currVal_4 = (i0.ɵnov(_v, 10)._animationMode === "NoopAnimations"); var currVal_5 = (i0.ɵnov(_v, 11).ariaLabel || null); var currVal_6 = i0.ɵnov(_v, 11).type; _ck(_v, 9, 0, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = (i0.ɵnov(_v, 14).disabled || null); var currVal_9 = (i0.ɵnov(_v, 14)._animationMode === "NoopAnimations"); var currVal_10 = (i0.ɵnov(_v, 15).ariaLabel || null); var currVal_11 = i0.ɵnov(_v, 15).type; _ck(_v, 13, 0, currVal_8, currVal_9, currVal_10, currVal_11); }); }
export function View_ConfirmationDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-connected-user-dialog", [], null, null, null, View_ConfirmationDialogComponent_0, RenderType_ConfirmationDialogComponent)), i0.ɵdid(1, 49152, null, 0, i6.ConfirmationDialogComponent, [i1.MAT_DIALOG_DATA], null, null)], null, null); }
var ConfirmationDialogComponentNgFactory = i0.ɵccf("app-connected-user-dialog", i6.ConfirmationDialogComponent, View_ConfirmationDialogComponent_Host_0, {}, {}, []);
export { ConfirmationDialogComponentNgFactory as ConfirmationDialogComponentNgFactory };

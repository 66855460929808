/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/list";
import * as i2 from "../../../../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i3 from "ngx-permissions";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../../node_modules/@angular/material/divider/typings/index.ngfactory";
import * as i6 from "@angular/material/divider";
import * as i7 from "./nav-menu.component";
import * as i8 from "../../core/api-services/auth.service";
import * as i9 from "@angular/router";
var styles_NavMenuComponent = [];
var RenderType_NavMenuComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NavMenuComponent, data: {} });
export { RenderType_NavMenuComponent as RenderType_NavMenuComponent };
function View_NavMenuComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "h3", [["class", "mat-subheader"], ["mat-subheader", ""]], null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i1.MatListSubheaderCssMatStyler, [], null, null), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.name; _ck(_v, 2, 0, currVal_0); }); }
function View_NavMenuComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "a", [["class", "mat-list-item"], ["mat-list-item", ""]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatListItem_0, i2.RenderType_MatListItem)), i0.ɵdid(1, 1228800, null, 3, i1.MatListItem, [i0.ElementRef, i0.ChangeDetectorRef, [2, i1.MatNavList], [2, i1.MatList]], null, null), i0.ɵqud(603979776, 1, { _lines: 1 }), i0.ɵqud(603979776, 2, { _avatar: 0 }), i0.ɵqud(603979776, 3, { _icon: 0 }), (_l()(), i0.ɵted(5, 2, ["", ""]))], null, function (_ck, _v) { var currVal_0 = (i0.ɵnov(_v, 1)._avatar || i0.ɵnov(_v, 1)._icon); var currVal_1 = (i0.ɵnov(_v, 1)._avatar || i0.ɵnov(_v, 1)._icon); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _v.parent.context.$implicit.name; _ck(_v, 5, 0, currVal_2); }); }
function View_NavMenuComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavMenuComponent_5)), i0.ɵdid(2, 737280, null, 0, i3.NgxPermissionsDirective, [i3.NgxPermissionsService, i3.NgxPermissionsConfigurationService, i3.NgxRolesService, i0.ViewContainerRef, i0.ChangeDetectorRef, i0.TemplateRef], { ngxPermissionsOnly: [0, "ngxPermissionsOnly"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.permissions; _ck(_v, 2, 0, currVal_0); }, null); }
function View_NavMenuComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavMenuComponent_3)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavMenuComponent_4)), i0.ɵdid(4, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 1, "mat-divider", [["class", "mat-divider"], ["role", "separator"]], [[1, "aria-orientation", 0], [2, "mat-divider-vertical", null], [2, "mat-divider-horizontal", null], [2, "mat-divider-inset", null]], null, null, i5.View_MatDivider_0, i5.RenderType_MatDivider)), i0.ɵdid(6, 49152, null, 0, i6.MatDivider, [], null, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.name; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.links; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = (i0.ɵnov(_v, 6).vertical ? "vertical" : "horizontal"); var currVal_3 = i0.ɵnov(_v, 6).vertical; var currVal_4 = !i0.ɵnov(_v, 6).vertical; var currVal_5 = i0.ɵnov(_v, 6).inset; _ck(_v, 5, 0, currVal_2, currVal_3, currVal_4, currVal_5); }); }
function View_NavMenuComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavMenuComponent_2)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isVisible(_v.context.$implicit); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_NavMenuComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "mat-nav-list", [["class", "mat-nav-list mat-list-base"], ["role", "navigation"]], null, null, null, i2.View_MatNavList_0, i2.RenderType_MatNavList)), i0.ɵdid(1, 704512, null, 0, i1.MatNavList, [], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 3, "mat-list", [["class", "mat-list mat-list-base"]], null, null, null, i2.View_MatList_0, i2.RenderType_MatList)), i0.ɵdid(3, 704512, null, 0, i1.MatList, [i0.ElementRef], null, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_NavMenuComponent_1)), i0.ɵdid(5, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.menu; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_NavMenuComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-nav-menu", [], null, null, null, View_NavMenuComponent_0, RenderType_NavMenuComponent)), i0.ɵdid(1, 114688, null, 0, i7.NavMenuComponent, [i3.NgxPermissionsService, i3.NgxRolesService, i8.AuthService, i9.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavMenuComponentNgFactory = i0.ɵccf("app-nav-menu", i7.NavMenuComponent, View_NavMenuComponent_Host_0, {}, { onNavigate: "onNavigate" }, []);
export { NavMenuComponentNgFactory as NavMenuComponentNgFactory };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@angular/material/tooltip";
import * as i5 from "@angular/cdk/overlay";
import * as i6 from "@angular/cdk/scrolling";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/cdk/bidi";
import * as i10 from "@angular/platform-browser";
import * as i11 from "./footer.component";
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
export function View_FooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "div", [["class", "footer"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 18, "p", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" K-App "])), (_l()(), i1.ɵeld(4, 16777216, null, null, 2, "a", [["class", "link"], ["matTooltipShowDelay", "1000"]], [[8, "href", 4]], [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 5)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 212992, null, 0, i4.MatTooltip, [i5.Overlay, i1.ElementRef, i6.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i7.Platform, i8.AriaDescriber, i8.FocusMonitor, i4.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i9.Directionality], [2, i4.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i10.HAMMER_LOADER]], { showDelay: [0, "showDelay"], message: [1, "message"] }, null), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" | "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Serviam Indeclinabilem"])), (_l()(), i1.ɵted(-1, null, [" | "])), (_l()(), i1.ɵeld(11, 16777216, null, null, 2, "span", [["matTooltipShowDelay", "1000"]], null, [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 12)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 12)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 212992, null, 0, i4.MatTooltip, [i5.Overlay, i1.ElementRef, i6.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i7.Platform, i8.AriaDescriber, i8.FocusMonitor, i4.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i9.Directionality], [2, i4.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i10.HAMMER_LOADER]], { showDelay: [0, "showDelay"], message: [1, "message"] }, null), (_l()(), i1.ɵted(13, null, [" \u00A9 ", ""])), (_l()(), i1.ɵeld(14, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Coded with "])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "span", [["class", "accent"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u2665"])), (_l()(), i1.ɵted(-1, null, [" by La Konch' & "])), (_l()(), i1.ɵeld(19, 0, null, null, 1, "a", [["class", "link"], ["href", "https://github.com/K-Fet/K-App/graphs/contributors"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["La team K-App"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "center center"; _ck(_v, 1, 0, currVal_0); var currVal_2 = "1000"; var currVal_3 = i1.ɵinlineInterpolate(1, "", _co.tooltip, ""); _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_5 = "1000"; var currVal_6 = i1.ɵinlineInterpolate(1, "", _co.buildDate, ""); _ck(_v, 12, 0, currVal_5, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.link, ""); _ck(_v, 4, 0, currVal_1); var currVal_4 = _co.version; _ck(_v, 6, 0, currVal_4); var currVal_7 = _co.buildYear; _ck(_v, 13, 0, currVal_7); }); }
export function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 114688, null, 0, i11.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterComponentNgFactory = i1.ɵccf("app-footer", i11.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };

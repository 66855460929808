/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i2 from "@angular/material/progress-bar";
import * as i3 from "@angular/platform-browser/animations";
import * as i4 from "@angular/common";
import * as i5 from "./loader.component";
import * as i6 from "../../core/services/loader.service";
var styles_LoaderComponent = [];
var RenderType_LoaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LoaderComponent, data: {} });
export { RenderType_LoaderComponent as RenderType_LoaderComponent };
function View_LoaderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "loader-overlay"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar"], ["color", "accent"], ["mode", "indeterminate"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i1.View_MatProgressBar_0, i1.RenderType_MatProgressBar)), i0.ɵdid(3, 4374528, null, 0, i2.MatProgressBar, [i0.ElementRef, i0.NgZone, [2, i3.ANIMATION_MODULE_TYPE], [2, i2.MAT_PROGRESS_BAR_LOCATION]], { color: [0, "color"], mode: [1, "mode"] }, null)], function (_ck, _v) { var currVal_3 = "accent"; var currVal_4 = "indeterminate"; _ck(_v, 3, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = (((i0.ɵnov(_v, 3).mode === "indeterminate") || (i0.ɵnov(_v, 3).mode === "query")) ? null : i0.ɵnov(_v, 3).value); var currVal_1 = i0.ɵnov(_v, 3).mode; var currVal_2 = i0.ɵnov(_v, 3)._isNoopAnimation; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_LoaderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_LoaderComponent_1)), i0.ɵdid(1, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.show; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LoaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-loader", [], null, null, null, View_LoaderComponent_0, RenderType_LoaderComponent)), i0.ɵdid(1, 245760, null, 0, i5.LoaderComponent, [i6.LoaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoaderComponentNgFactory = i0.ɵccf("app-loader", i5.LoaderComponent, View_LoaderComponent_Host_0, {}, {}, []);
export { LoaderComponentNgFactory as LoaderComponentNgFactory };

import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AuthService } from './core/api-services/auth.service';
import { ToasterService } from './core/services/toaster.service';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
export class AppComponent {
    constructor(authService, toasterService, router) {
        this.authService = authService;
        this.toasterService = toasterService;
        this.router = router;
        this.title = 'K-App';
    }
    ngOnInit() {
        this.authService.$currentUser.subscribe((user) => {
            this.user = user;
        });
    }
    logout() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.authService.logout();
            this.toasterService.showToaster('Déconnexion réussie');
            this.router.navigate(['/']);
        });
    }
}

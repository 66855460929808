/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./modal.component";
import * as i2 from "@angular/material/dialog";
import * as i3 from "@angular/common";
var styles_ModalComponent = [];
var RenderType_ModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ModalComponent, data: {} });
export { RenderType_ModalComponent as RenderType_ModalComponent };
function View_ModalComponent_1(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 0), (_l()(), i0.ɵand(0, null, null, 0))], null, null); }
export function View_ModalComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { content: 0 }), (_l()(), i0.ɵand(0, [[1, 2], ["content", 2]], null, 0, null, View_ModalComponent_1))], null, null); }
export function View_ModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-modal", [], null, null, null, View_ModalComponent_0, RenderType_ModalComponent)), i0.ɵdid(1, 114688, null, 0, i1.ModalComponent, [i2.MatDialog, i3.Location], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalComponentNgFactory = i0.ɵccf("app-modal", i1.ModalComponent, View_ModalComponent_Host_0, { config: "config" }, {}, ["*"]);
export { ModalComponentNgFactory as ModalComponentNgFactory };

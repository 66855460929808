import { Routes } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
const ɵ0 = { permissions: { only: ['role:read', 'specialaccount:read'] } }, ɵ1 = () => import("./acl/acl.module.ngfactory").then(m => m.AclModuleNgFactory), ɵ2 = () => import("./auth/auth.module.ngfactory").then(m => m.AuthModuleNgFactory), ɵ3 = { permissions: { only: 'barman:read' } }, ɵ4 = () => import("./barmen/barmen.module.ngfactory").then(m => m.BarmenModuleNgFactory), ɵ5 = () => import("./contact/contact.module.ngfactory").then(m => m.ContactModuleNgFactory), ɵ6 = () => import("./inventory-management/inventory-management.module.ngfactory")
    .then(m => m.InventoryManagementModuleNgFactory), ɵ7 = { permissions: { only: 'kommission:read' } }, ɵ8 = () => import("./kommissions/kommissions.module.ngfactory").then(m => m.KommissionsModuleNgFactory), ɵ9 = { permissions: { only: 'member:read' } }, ɵ10 = () => import("./members/members.module.ngfactory").then(m => m.MembersModuleNgFactory), ɵ11 = { permissions: { only: 'service:read' } }, ɵ12 = () => import("./services/services.module.ngfactory").then(m => m.ServicesModuleNgFactory), ɵ13 = () => import("./home/home.module.ngfactory").then(m => m.HomeModuleNgFactory);
const routes = [
    {
        path: 'acl',
        data: ɵ0,
        canLoad: [NgxPermissionsGuard],
        loadChildren: ɵ1,
    },
    {
        path: 'auth',
        loadChildren: ɵ2,
    },
    {
        path: 'barmen',
        data: ɵ3,
        canLoad: [NgxPermissionsGuard],
        loadChildren: ɵ4,
    },
    {
        path: 'contact',
        loadChildren: ɵ5,
    },
    {
        path: 'inventory-management',
        loadChildren: ɵ6,
    },
    {
        path: 'kommissions',
        data: ɵ7,
        canLoad: [NgxPermissionsGuard],
        loadChildren: ɵ8,
    },
    {
        path: 'members',
        data: ɵ9,
        canLoad: [NgxPermissionsGuard],
        loadChildren: ɵ10,
    },
    {
        path: 'services',
        data: ɵ11,
        canLoad: [NgxPermissionsGuard],
        loadChildren: ɵ12,
    },
    {
        path: 'home',
        loadChildren: ɵ13,
    },
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full',
    },
    {
        path: '**',
        component: NotFoundComponent,
    },
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13 };

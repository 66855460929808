<div class="app-container">
  <app-loader></app-loader>

  <mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="start center">
    <button mat-icon-button (click)="sn.toggle()">
      <mat-icon>menu</mat-icon>
    </button>

    <a mat-button class="home" routerLink="/">K-App</a>

    <div fxFlex></div>

    <mat-menu #userInfo="matMenu" [overlapTrigger]="false" class="connected-user-information">
      <div *ngIf="user?.barman">
        <h4>Compte Barman {{user.barman.nickname}}</h4>
        <p>Membre depuis le {{user.barman.createdAt | date:'d LLLL yyyy':'':'fr'}}</p>
        <div fxLayoutAlign="end">
          <a mat-raised-button routerLink="/barmen/{{user.barman.id}}/edit">Modifier mes informations</a>
        </div>
      </div>
      <div *ngIf="user?.specialAccount">
        <h4>Compte spécial {{user.specialAccount.connection.email}}</h4>
        <p>Membre depuis le {{user.specialAccount.createdAt | date:'d LLLL yyyy':'':'fr'}}</p>
        <div fxLayoutAlign="end">
          <a mat-raised-button routerLink="/acl/special-accounts/{{user.specialAccount.id}}/edit">Modifier mes
            informations</a>
        </div>
      </div>
    </mat-menu>

    <div *ngIf="!user?.isGuest()">
      <button mat-icon-button [matMenuTriggerFor]="userInfo">
        <mat-icon class="example-icon">person</mat-icon>
      </button>

      <button type="button" mat-icon-button (click)="logout()">
        <mat-icon>power_settings_new</mat-icon>
      </button>
    </div>

    <div *ngIf="user?.isGuest()">
      <button mat-stroked-button type="button" routerLink="/auth/login">
        Se connecter
      </button>
    </div>
  </mat-toolbar>

  <mat-sidenav-container class="content-container">
    <mat-sidenav #sn>
      <app-nav-menu (onNavigate)="sn.close()"></app-nav-menu>
    </mat-sidenav>

    <mat-sidenav-content class="content">
      <router-outlet></router-outlet>
      <router-outlet name="modal"></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <footer>
    <app-footer></app-footer>
  </footer>
</div>

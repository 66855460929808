import { Type } from '@angular/core';
import { DynamicOwlDateTimeComponent } from './dynamic-owl-date-time/dynamic-owl-date-time.component';
import { DYNAMIC_FORM_CONTROL_TYPE_DATEPICKER, DynamicFormControl, DynamicFormControlModel, } from '@ng-dynamic-forms/core';
const ɵ0 = (model) => {
    if (model.type === DYNAMIC_FORM_CONTROL_TYPE_DATEPICKER)
        return DynamicOwlDateTimeComponent;
};
export class DynamicControlsModule {
}
export { ɵ0 };

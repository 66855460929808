import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { AccountType, ConnectedUser } from '../../shared/models';
import * as jwt_decode from 'jwt-decode';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { ROLES } from '../../constants';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { toURL } from './api-utils';
import { clearBugsnagUser, setBugsnagUser } from '../bugsnag-client';
export class AuthService {
    constructor(http, ngxPermissionsService, ngxRolesService) {
        this.http = http;
        this.ngxPermissionsService = ngxPermissionsService;
        this.ngxRolesService = ngxRolesService;
        // Suppose the user is already logged in
        // Prevent a redirection
        this.isLoggedIn = true;
    }
    initializeAuth() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.$currentUser = new BehaviorSubject(new ConnectedUser({
                accountType: AccountType.GUEST,
                createdAt: new Date(),
            }));
            const currentUser = Object.assign({}, JSON.parse(localStorage.getItem('currentUser')), JSON.parse(sessionStorage.getItem('currentUser')));
            if (!currentUser.jwt)
                return;
            const jwtDecoded = jwt_decode(currentUser.jwt);
            // If the token expire in less than 12 hours,
            // disconnect the user to prevent being disturbed during his navigation
            if (Date.now() >= (jwtDecoded.exp * 1000 - 3600000))
                return this.clearUser();
            try {
                yield this.me();
            }
            catch (e) {
                console.error('Error during initializeAuth: ', e);
                this.clearUser();
            }
        });
    }
    login(email, password, rememberMe) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const currentUser = yield this.http.post(toURL('v1/auth/login'), {
                email,
                password,
                rememberMe,
            })
                .toPromise();
            this.saveUser(currentUser, (rememberMe >= environment.JWT_DAY_EXP_LONG));
            yield this.me();
        });
    }
    logout() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.http.get(toURL('v1/auth/logout')).toPromise();
            }
            catch (e) { }
            this.clearUser();
        });
    }
    definePassword(email, password, passwordToken, oldPassword) {
        const body = {
            email,
            password,
            // Change null into undefined
            passwordToken: passwordToken || undefined,
            oldPassword: oldPassword || undefined,
        };
        return this.http.put(toURL('v1/auth/reset-password'), body).toPromise();
    }
    verifyEmail(userId, email, password, emailToken) {
        return this.http.post(toURL('v1/auth/email-verification'), {
            userId,
            email,
            password,
            emailToken,
        }).toPromise();
    }
    cancelEmailUpdate(userId, email) {
        return this.http.post(toURL('v1/auth/cancel-email-verification'), {
            userId,
            email,
        }).toPromise();
    }
    resetPassword(email) {
        return this.http.post(toURL('v1/auth/reset-password'), { email }).toPromise();
    }
    clearUser() {
        clearBugsnagUser();
        this.$currentUser.next(new ConnectedUser({
            accountType: AccountType.GUEST,
            createdAt: new Date(),
        }));
        this.ngxPermissionsService.flushPermissions();
        this.ngxRolesService.flushRoles();
        if (localStorage.getItem('currentUser'))
            localStorage.removeItem('currentUser');
        if (sessionStorage.getItem('currentUser'))
            sessionStorage.removeItem('currentUser');
        this.isLoggedIn = false;
    }
    saveUser(currentUser, rememberMe) {
        if (rememberMe) {
            localStorage.setItem('currentUser', JSON.stringify(currentUser));
        }
        else {
            sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
        }
    }
    managePermissionAndRole(permissions) {
        this.ngxPermissionsService.addPermission(permissions);
        ROLES
            .filter(ROLE => ROLE.permissions.every(p => permissions.includes(p)))
            .forEach(ROLE => this.ngxRolesService.addRole(ROLE.name, ROLE.permissions));
    }
    me() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const { user, permissions } = yield this.http.get(toURL('v1/me')).toPromise();
            this.isLoggedIn = true;
            const { barman, specialAccount } = user;
            if (barman) {
                this.$currentUser.next(new ConnectedUser({
                    barman,
                    accountType: AccountType.BARMAN,
                    email: barman.connection.email,
                    createdAt: barman.createdAt,
                }));
                this.ngxRolesService.addRole('BARMAN', ['']);
            }
            else if (specialAccount) {
                this.$currentUser.next(new ConnectedUser({
                    specialAccount,
                    accountType: AccountType.SPECIAL_ACCOUNT,
                    email: specialAccount.connection.email,
                    createdAt: specialAccount.createdAt,
                }));
                this.ngxRolesService.addRole('SPECIAL_ACCOUNT', ['']);
            }
            setBugsnagUser(this.$currentUser.getValue());
            this.managePermissionAndRole(permissions);
        });
    }
}

<div fxLayoutAlign="center center" class="footer">
    <p class="text">
        K-App <a href="{{ link }}" class="link" matTooltip="{{ tooltip }}" matTooltipShowDelay="1000">{{ version }}</a>
        |
        <strong>Serviam Indeclinabilem</strong>
        |
        <span matTooltip="{{ buildDate }}" matTooltipShowDelay="1000"> © {{ buildYear }}</span>
        <br/>
        Coded with <span class="accent">&hearts;</span> by La Konch'
        & <a href="https://github.com/K-Fet/K-App/graphs/contributors" class="link">La team K-App</a>
    </p>
</div>
